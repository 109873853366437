import axios from 'axios'
// 某些內容需要authentication header才能取得
import authHeader from './auth-header'
import Config from '@/config/config'
const API_URL = Config.basicBackendBaseURL
const CALENDAR_URL = Config.calendarBackendURL

class UserService {
  addAppRecordToBufferDB () {
    // console.log('Add app content into buffer db ...')
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    return axios.get(API_URL + 'service/add-records', { headers: headerConfig })
  }

  getRecordInExcel () {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/octet-stream'
    // axios的response type設置成blob否則excel的檔案送來二進邊碼會自動編譯成json導致錯誤無法開啟(axios針對二進檔案內定是處理成json)
    return axios.get(API_URL + 'service/get-records-in-excel', { headers: headerConfig, responseType: 'blob' })
  }

  getAuthRecordAmount () {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    return axios.get(API_URL + 'service/get-records-amount', { headers: headerConfig })
  }

  getAuthRecordContentByNumber (n) {
    const dataForm = new FormData()
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    if (isNaN(n) || n < 0 || n === '') {
      n = 0
      // console.log(n)
      dataForm.append('number', n)
      return axios.post(API_URL + 'service/get-records-by-offset', dataForm, { headers: headerConfig })
    } else {
      n = Math.trunc(n)
      // console.log(n - 1)
      dataForm.append('number', n)
      return axios.post(API_URL + 'service/get-records-by-offset', dataForm, { headers: headerConfig })
    }
  }

  getAuthRecordContent (type) {
    // 設置三種條件0(指定的最新時間紀錄-後端指定數量)、1(指定的最末ID紀錄-後端指定數量)、2(未驗證上鏈紀錄-後端指定數量)
    // 0 API: /amount-unix-blockchain-records-uuid
    // 1 API: /amount-id-blockchain-records-uuid
    // 2 API: /all-unverified-blockchain-records-uuid
    // console.log('Get auth content ...')
    if (type === 0) {
      const headerConfig = authHeader()
      headerConfig.Accept = 'application/json'
      return axios.get(API_URL + 'blockchain/amount-unix-blockchain-records-uuid', { headers: headerConfig })
    } else if (type === 1) {
      const headerConfig = authHeader()
      headerConfig.Accept = 'application/json'
      return axios.get(API_URL + 'blockchain/amount-id-blockchain-records-uuid', { headers: headerConfig })
    } else if (type === 2) {
      const headerConfig = authHeader()
      headerConfig.Accept = 'application/json'
      return axios.get(API_URL + 'blockchain/all-unverified-blockchain-records-uuid', { headers: headerConfig })
    } else {
      // 其他情況，譬如空值 or other exceptions則都是unix時間列出指定數量的records
      const headerConfig = authHeader()
      headerConfig.Accept = 'application/json'
      return axios.get(API_URL + 'blockchain/amount-unix-blockchain-records-uuid', { headers: headerConfig })
    }
    // 下面這段是全部record取得的source code
    // const headerConfig = authHeader()
    // headerConfig.Accept = 'application/json'
    // return axios.get(API_URL + 'blockchain/all-blockchain-records-uuid', { headers: headerConfig })
    // ------------------------------------------------------------------------------------------------
    // const headerContent = { Accept: 'application/json' }
    // return axios.get(API_URL + 'service/all-records', { headers: headerContent })
  }

  checkAuthRecordContent () {
    // console.log('Get auth content ...')
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    return axios.get(API_URL + 'blockchain/recheck-record-verified', { headers: headerConfig })

    // const headerContent = { Accept: 'application/json' }
    // return axios.get(API_URL + 'service/all-records', { headers: headerContent })
  }

  // Hyperledger Fabric network connection checking
  checkConnection () {
    // console.log('Check blockchain connection ...')
    const headerContent = { Accept: 'application/json' }
    return axios.get(API_URL + 'blockchain/connection', { headers: headerContent })
  }

  // App backend connection checking
  checkAppConnection () {
    // console.log('Check blockchain connection ...')
    const headerContent = { Accept: 'application/json' }
    return axios.get(API_URL + 'service/app-connection', { headers: headerContent })
  }

  switchErrorTag (recordId) {
    // console.log('Switch blockchain err tag ...')
    const dataForm = new FormData()
    dataForm.append('record_id', recordId)
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.post(API_URL + 'blockchain/switch-record-err-tag', dataForm, { headers: headerConfig })
  }

  GenerateNewNode (product) {
    // console.log('Generate node ...')
    const dataForm = new FormData()
    dataForm.append('product', product)
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.post(API_URL + 'graph/generate-nodes', dataForm, { headers: headerConfig })
  }

  GenerateNewGraph (product) {
    // console.log('Generate graph structure and style ...')
    const dataForm = new FormData()
    dataForm.append('product', product)
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.post(API_URL + 'graph/generate-graph-structure-style', dataForm, { headers: headerConfig })
  }

  GetRecordFromApp () {
    // console.log('Get Records From App ...')
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.get(API_URL + 'service/add-records', { headers: headerConfig })
  }

  SendBufferRecordToBlockchain () {
    // console.log('Verify Buffer Records To Blockchain ...')
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.get(API_URL + 'blockchain/add-all-records', { headers: headerConfig })
  }

  RecheckRecordVerified () {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.get(API_URL + 'blockchain/recheck-record-verified', { headers: headerConfig })
  }

  // ------------------栽培曆相關功能func or API呼叫------------------
  getCropStationOptions () {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.get(CALENDAR_URL + 'service/get-crop-station-options', { headers: headerConfig })
  }

  transformStatusDateDictToChartAnnotation (data) {
    return Object.entries(data).map(([text, date]) => {
      return {
        x: new Date(date).getTime(),
        borderColor: '#ff0000',
        label: {
          text: text,
          style: {
            fontSize: '14px',
            color: '#ffffff',
            background: '#ff0000'
          }
        }
      }
    })
  }

  setNotificationBroadcast (date, action) {
    const dataForm = new FormData()
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data; boundary=WebAppBoundary'
    dataForm.append('date', date)
    dataForm.append('action', action)
    return axios.post(CALENDAR_URL + 'service/send-todo-item-to-app-backend', dataForm, { headers: headerConfig })
  }

  getALlComputedResultForDynamicCalendar (crop, date, station) {
    const dataForm = new FormData()
    const headerConfig = authHeader()
    dataForm.append('crop', crop)
    dataForm.append('date', date)
    dataForm.append('station', station)
    const dynamicCalendarRequiredRequest = axios.create({
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
    })
    return dynamicCalendarRequiredRequest.post(CALENDAR_URL + '/service/get-all-computed-result-for-cal', dataForm, { headers: headerConfig })
  }

  // ----從calendarBackend代理取得:App履歷標籤資訊、從calendarBackend代理建立: 於App去建立待辦Task API-----
  getAllActionInfoFromAppBackend () {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.get(CALENDAR_URL + 'service/get-action-info-from-app-backend', { headers: headerConfig })
  }

  sendTaskInfoToAppBackendToBuildTask (date, action, actionSet, taskValue) {
    const dataForm = new FormData()
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data; boundary=WebAppBoundary'
    const jsonActionSetString = JSON.stringify(actionSet)
    dataForm.append('date', date)
    dataForm.append('action', action)
    dataForm.append('action_set', jsonActionSetString)
    dataForm.append('task_value', taskValue)
    return axios.post(CALENDAR_URL + 'service/send-task-info-to-app-backend', dataForm, { headers: headerConfig })
  }

  // ------------------知識圖譜相關功能func or API呼叫------------------

  getProductList () {
    const productListRequest = axios.create({
      baseURL: API_URL,
      // Accept: 'application/json' 表示要接收的是JSON
      headers: { Accept: 'application/json' }
    })
    return productListRequest.get('graph/product-list')
  }

  getProductCodeList (product) {
    const dataForm = new FormData()
    dataForm.append('product', product)
    const productCodeListRequest = axios.create({
      baseURL: API_URL,
      // Accept: 'application/json' 表示要接收的是JSON
      headers: { Accept: 'application/json' }
    })
    return productCodeListRequest.post('graph/product-code-list', dataForm)
  }

  getGraph (name, uLevel, dLevel, product) {
    const dataForm = new FormData()
    dataForm.append('name', name)
    dataForm.append('u_level', uLevel)
    dataForm.append('d_level', dLevel)
    dataForm.append('product', product)
    const graphRequest = axios.create({
      baseURL: Config.graphBackend,
      // Accept: 'application/json' 表示要接收的是JSON
      headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
    })
    return graphRequest.post('/service/graph', dataForm)
  }

  showDetail (nodeName) {
    const dataForm = new FormData()
    dataForm.append('node', nodeName)
    const userRequest = axios.create({
      baseURL: Config.basicBackendBaseURL,
      // Accept: 'html/text' 表示要接收的是text
      headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
    })
    return userRequest.post('/graph/node-name-info', dataForm)
  }

  getTerm (chinese) {
    const dataForm = new FormData()
    dataForm.append('chinese', chinese)
    const userRequest = axios.create({
      baseURL: Config.basicBackendBaseURL,
      // Accept: 'html/text' 表示要接收的是text
      headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
    })
    return userRequest.post('/graph/term-chinese', dataForm)
  }

  showRecords (product, detail) {
    const dataForm = new FormData()
    dataForm.append('product', product)
    dataForm.append('detail', detail)
    const userRequest = axios.create({
      baseURL: Config.basicBackendBaseURL,
      // Accept: 'html/text' 表示要接收的是text
      headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
    })
    return userRequest.post('/service/product-detail-all-records', dataForm)
  }

  maskGetGraph (name, uLevel, dLevel, product) {
    const dataForm = new FormData()
    dataForm.append('name', name)
    dataForm.append('u_level', uLevel)
    dataForm.append('d_level', dLevel)
    dataForm.append('product', product)
    const graphRequest = axios.create({
      baseURL: Config.graphBackend,
      // Accept: 'application/json' 表示要接收的是JSON
      headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
    })
    return graphRequest.post('/service/graph', dataForm)
  }

  // ------------------履歷表格相關功能func or API呼叫------------------
  getDetailRecordByProductWithAuth (product) {
    const dataForm = new FormData()
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data; boundary=WebAppBoundary'
    dataForm.append('product', product)
    return axios.post(Config.basicBackendBaseURL + 'service/all-records-by-product-with-auth', dataForm, { headers: headerConfig })
  }
}
// 輸出的會是建立的UserService instance
export default new UserService()
